<template>
  <svg
    width="123"
    height="44"
    viewBox="0 0 123 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="122.909" height="43.0556" rx="8.61111" fill="white" />
    <path
      d="M33.6509 26.6988C33.1551 27.7989 32.918 28.2896 32.2785 29.262C31.3875 30.6191 30.1301 32.3105 28.5745 32.3248C27.1913 32.3374 26.8356 31.4243 24.9585 31.4351C23.0813 31.4459 22.6897 32.341 21.3065 32.3284C19.7509 32.3141 18.5599 30.788 17.669 29.4291C15.1757 25.6329 14.917 21.1771 16.4529 18.8098C17.545 17.1256 19.2677 16.1406 20.888 16.1406C22.537 16.1406 23.5753 17.0465 24.9387 17.0465C26.2626 17.0465 27.0692 16.1406 28.9751 16.1406C30.4175 16.1406 31.9444 16.9261 33.033 18.285C29.4673 20.2388 30.0475 25.3364 33.6509 26.6988Z"
      fill="#222222" />
    <path
      d="M27.5284 14.6257C28.2218 13.7359 28.7481 12.4777 28.5559 11.1943C27.4242 11.2716 26.1003 11.9924 25.3261 12.9325C24.6237 13.7863 24.0453 15.0499 24.2698 16.2793C25.5075 16.3171 26.7865 15.5783 27.5284 14.6257Z"
      fill="#222222" />
    <path
      d="M40.3814 16.81C41.1556 16.81 41.8587 16.4624 42.0799 15.8304V16.7152H42.704V14.2582H40.5631V14.7955H42.0799C41.993 15.6487 41.3926 16.2333 40.4604 16.2333C39.386 16.2333 38.6592 15.467 38.6592 14.2345C38.6592 13.0021 39.3781 12.2279 40.3972 12.2279C41.4005 12.2279 41.9851 12.8283 42.0404 13.7368L42.6961 13.6973C42.6092 12.4254 41.7955 11.6433 40.3893 11.6433C39.0147 11.6433 37.9561 12.694 37.9561 14.2266C37.9561 15.7751 38.9752 16.81 40.3814 16.81Z"
      fill="#222222" />
    <path
      d="M46.2154 15.4907C46.0732 16.02 45.7335 16.2965 45.1568 16.2965C44.5406 16.2965 44.0587 15.8225 44.0271 15.151H46.7763V14.8903C46.7763 13.9501 46.2154 13.168 45.1173 13.168C44.1219 13.168 43.3714 13.9501 43.3714 15.0404C43.3714 16.1148 44.1219 16.81 45.1489 16.81C46.01 16.81 46.5867 16.336 46.7605 15.5539L46.2154 15.4907ZM44.0429 14.7244C44.1219 14.1555 44.4932 13.6657 45.1173 13.6657C45.773 13.6657 46.0811 14.1713 46.1048 14.7244H44.0429Z"
      fill="#222222" />
    <path
      d="M47.5763 15.9094C47.5763 16.5809 47.9239 16.81 48.4137 16.81C48.7929 16.81 49.0457 16.7073 49.2985 16.5177V16.0753C49.101 16.1859 48.8719 16.2807 48.6507 16.2807C48.3505 16.2807 48.2241 16.1385 48.2241 15.8304V13.7368H49.1958V13.2628H48.2241V12.3069H47.8528L47.7501 12.7888C47.6711 13.1206 47.6 13.2786 47.2761 13.3418L47.0549 13.3813V13.7368H47.5763V15.9094Z"
      fill="#222222" />
    <path
      d="M51.2921 13.2628V16.7152H51.9478V13.2628H51.2921ZM51.2763 12.4807H51.9557V11.7381H51.2763V12.4807Z"
      fill="#222222" />
    <path
      d="M52.9613 15.9094C52.9613 16.5809 53.3089 16.81 53.7987 16.81C54.1779 16.81 54.4307 16.7073 54.6835 16.5177V16.0753C54.486 16.1859 54.2569 16.2807 54.0357 16.2807C53.7355 16.2807 53.6091 16.1385 53.6091 15.8304V13.7368H54.5808V13.2628H53.6091V12.3069H53.2378L53.1351 12.7888C53.0561 13.1206 52.985 13.2786 52.6611 13.3418L52.4399 13.3813V13.7368H52.9613V15.9094Z"
      fill="#222222" />
    <path
      d="M57.1274 14.993C57.1274 14.2187 57.5145 13.6815 58.2098 13.6815C58.9129 13.6815 59.3079 14.2187 59.3079 14.993C59.3079 15.7593 58.9129 16.3044 58.2098 16.3044C57.5145 16.3044 57.1274 15.7593 57.1274 14.993ZM59.9794 14.993C59.9794 13.9185 59.2289 13.168 58.2098 13.168C57.1985 13.168 56.4559 13.9185 56.4559 14.993C56.4559 16.0674 57.1985 16.81 58.2098 16.81C59.2289 16.81 59.9794 16.0674 59.9794 14.993Z"
      fill="#222222" />
    <path
      d="M61.3138 14.7323C61.3138 14.1002 61.7246 13.6894 62.246 13.6894C62.7911 13.6894 63.0676 14.0528 63.0676 14.6295V16.7152H63.7233V14.5742C63.7233 13.7684 63.3599 13.168 62.4593 13.168C61.9458 13.168 61.4797 13.4603 61.3138 13.879V13.2628H60.658V16.7152H61.3138V14.7323Z"
      fill="#222222" />
    <path
      d="M42.0046 19.1554L37.9561 29.2767H40.398L41.2495 27.0918H45.3301L46.1977 29.2767H48.8003L44.7196 19.1554H42.0046ZM43.2577 21.9348H43.3219L44.6232 25.2603H41.9564L43.2577 21.9348Z"
      fill="#222222" />
    <path
      d="M51.8959 25.6298C51.8959 24.4892 52.5546 23.7662 53.5185 23.7662C54.611 23.7662 55.1411 24.5534 55.1411 25.7102C55.1411 26.8829 54.611 27.6702 53.5185 27.6702C52.5546 27.6702 51.8959 26.9472 51.8959 25.7905V25.6298ZM51.8959 28.0075C52.2493 28.8429 53.0687 29.4695 54.3057 29.4695C56.3942 29.4695 57.4867 27.8147 57.4867 25.7102C57.4867 23.6216 56.3942 21.9508 54.3057 21.9508C53.0687 21.9508 52.2493 22.5774 51.8959 23.4289V22.1436H49.5825V31.8793H51.8959V28.0075Z"
      fill="#222222" />
    <path
      d="M60.9014 25.6298C60.9014 24.4892 61.56 23.7662 62.524 23.7662C63.6164 23.7662 64.1466 24.5534 64.1466 25.7102C64.1466 26.8829 63.6164 27.6702 62.524 27.6702C61.56 27.6702 60.9014 26.9472 60.9014 25.7905V25.6298ZM60.9014 28.0075C61.2548 28.8429 62.0741 29.4695 63.3112 29.4695C65.3997 29.4695 66.4922 27.8147 66.4922 25.7102C66.4922 23.6216 65.3997 21.9508 63.3112 21.9508C62.0741 21.9508 61.2548 22.5774 60.9014 23.4289V22.1436H58.5879V31.8793H60.9014V28.0075Z"
      fill="#222222" />
    <path
      d="M70.0464 25.678C70.0464 28.1361 71.9743 29.4695 74.6733 29.4695C76.9867 29.4695 78.8021 28.3931 78.8021 26.3046C78.8021 24.6498 77.8542 23.6216 75.7818 23.2521L73.886 22.9148C73.147 22.7702 72.6972 22.5613 72.6972 21.983C72.6972 21.2921 73.2756 20.8744 74.3038 20.8744C75.4765 20.8744 76.2959 21.4528 76.3601 22.6416L78.6093 22.5774C78.5611 20.3282 77.067 18.9626 74.3198 18.9626C71.8618 18.9626 70.3034 20.1997 70.3034 22.1918C70.3034 23.9108 71.4441 24.8105 73.0506 25.1157L74.9464 25.4531C75.83 25.6298 76.4244 25.7905 76.4244 26.4652C76.4244 27.14 75.7496 27.5577 74.6733 27.5577C73.4202 27.5577 72.3759 26.9954 72.2795 25.5977L70.0464 25.678Z"
      fill="#222222" />
    <path
      d="M80.3075 27.3649C80.3075 28.9715 81.3517 29.4695 82.5085 29.4695C83.4403 29.4695 84.0507 29.2446 84.5809 28.8429V27.3167C84.1471 27.5095 83.7776 27.6059 83.4242 27.6059C82.878 27.6059 82.5888 27.3488 82.5888 26.7384V23.7662H84.4845V22.1436H82.5888V20.1676H81.159L80.918 21.1154C80.7413 21.7741 80.5324 22.1436 79.8255 22.2721L79.3114 22.3525V23.7662H80.3075V27.3649Z"
      fill="#222222" />
    <path
      d="M87.5483 25.7102C87.5483 24.5213 88.0142 23.702 89.0423 23.702C90.0545 23.702 90.5525 24.5213 90.5525 25.7102C90.5525 26.899 90.0545 27.7183 89.0423 27.7183C88.0142 27.7183 87.5483 26.899 87.5483 25.7102ZM92.8981 25.7102C92.8981 23.5092 91.3237 21.9508 89.0423 21.9508C86.761 21.9508 85.2027 23.5092 85.2027 25.7102C85.2027 27.9272 86.761 29.4695 89.0423 29.4695C91.3237 29.4695 92.8981 27.9272 92.8981 25.7102Z"
      fill="#222222" />
    <path
      d="M99.2992 22.24C99.1064 22.0794 98.7369 21.9508 98.3031 21.9508C97.3392 21.9508 96.6002 22.6416 96.327 23.4449V22.1436H94.0136V29.2767H96.327V25.9029C96.327 24.6659 96.9697 24.1197 97.9336 24.1197C98.4316 24.1197 98.7851 24.2 99.1546 24.4089L99.2992 22.24Z"
      fill="#222222" />
    <path
      d="M105.116 26.7544C104.859 27.4131 104.361 27.7183 103.557 27.7183C102.658 27.7183 101.951 27.1721 101.886 26.2243H107.027V25.5174C107.027 23.5734 105.79 21.9508 103.429 21.9508C101.244 21.9508 99.6052 23.5734 99.6052 25.8226C99.6052 28.0879 101.212 29.4695 103.477 29.4695C105.341 29.4695 106.61 28.5538 106.979 26.9311L105.116 26.7544ZM101.919 24.9872C102.047 24.2321 102.481 23.6377 103.413 23.6377C104.296 23.6377 104.746 24.2803 104.794 24.9872H101.919Z"
      fill="#222222" />
  </svg>
</template>
